import React from "react";
import { getImageSize } from "../../../../sanity";

const DecoratorMobileOne = () => {
  const { height, width } = getImageSize(
    "21c5526cb3b196a5613b76a595a6d26c0537c80b-375x190.webp"
  );
  return (
    <>
      <img
        loading="lazy"
        height={height}
        width={width}
        className="w-full"
        src="https://cdn.sanity.io/images/imclblh9/production/21c5526cb3b196a5613b76a595a6d26c0537c80b-375x190.webp"
        alt="decorator"
      />
    </>
  );
};
export default DecoratorMobileOne;
