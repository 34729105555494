import React, { useEffect, useRef } from "react";
import ReactPlayer from "react-player/youtube";
import AboutMobileDecorator from "../pageComponents/decorators/decoratorMobileThree";
import AboutDesktopDecorator from "../pageComponents/decorators/decoratorMoreCircles";
import HomeDesktopDecorator from "../pageComponents/decorators/decoratorSmall";
import HomeMobileDecorator from "../pageComponents/decorators/decoratorMobile";
const VideoSection = ({
  section: { title, url, desktopLayout, mobileLayout },
}) => {
  let isAbout =
    typeof window !== "undefined" &&
    window.location.pathname.indexOf("/author") !== -1;
  let isHome =
    typeof window !== "undefined" && window.location.pathname === "/";
  let KeyNoteREf = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined" && KeyNoteREf) {
      let _url = window.location.href.indexOf("section") !== -1;
      if (_url) {
        let _checkId = window.location.href.split("section=")[1];
        if (_checkId) {
          setTimeout(() => {
            KeyNoteREf.current.scrollIntoView();
          }, 1000);
        }
      }
    }
  }, [KeyNoteREf]);
  return (
    <div>
      {isAbout ? (
        <div ref={KeyNoteREf}>
          <div className="lg:hidden">
            <AboutMobileDecorator />
          </div>
          <div className="hidden lg:block -mt-20">
            <AboutDesktopDecorator />
          </div>
        </div>
      ) : (
        <div>
          {isHome && (
            <div>
              <div className="lg:hidden">
                <HomeMobileDecorator />
              </div>
              <div className="hidden lg:block">
                <HomeDesktopDecorator />
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={`lg:w-886 lg:mx-auto px-5 lg:px-0 ${
          mobileLayout === "Center" ? "text-center lg:text-left" : "pl-5"
        }
          ${
            desktopLayout === "Center"
              ? "w-full textCenter pl-0 lg:pl-32"
              : "text-left"
          }
        ${isAbout ? "-mt-28 lg:-mt-0 " : ""}
         `}
      >
        <h1
          className={`text-5xl lg:text-100 font-black leading-54 lg:leading-110 font-Brother lg:font-Dazzed md:px-5 lg:px-0`}
        >
          {title ? title : ""}
        </h1>
      </div>
      <div
        className={`w-full lg:w-886 h-64 md:h-24 lg:h-130 mx-auto ${
          title ? "mt-8 lg:mt-16" : "mt-0"
        }  h-60 lg:h-96 px-5 md:px-11 lg:px-0`}
      >
        <ReactPlayer
          className="youtube-player"
          style={{ width: "100%" }}
          url={url || ""}
        />
      </div>
    </div>
  );
};
export default VideoSection;
