import React from "react";
import { getImageSize } from "../../../../sanity";

const DecoratorSmall = () => {
  const { height, width } = getImageSize("7fa21ddeca52b4303fa142a422de7e64acd4eb57-1440x190.webp");
  return (
    <>
      <img height={height} width={width} className="w-full" src="https://cdn.sanity.io/images/imclblh9/production/7fa21ddeca52b4303fa142a422de7e64acd4eb57-1440x190.webp" alt="decoratior-Circles" loading="lazy" />
    </>
  );
};

export default DecoratorSmall;
