import React from "react";
const YouTube = () => {
  return (
    <>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.7908 6.30068C20.7908 6.30068 20.5857 4.85283 19.9541 4.21709C19.1543 3.38037 18.2602 3.37627 17.85 3.32705C14.9133 3.11377 10.5041 3.11377 10.5041 3.11377H10.4959C10.4959 3.11377 6.08672 3.11377 3.15 3.32705C2.73984 3.37627 1.8457 3.38037 1.0459 4.21709C0.414258 4.85283 0.213281 6.30068 0.213281 6.30068C0.213281 6.30068 0 8.00283 0 9.70088V11.2923C0 12.9903 0.20918 14.6925 0.20918 14.6925C0.20918 14.6925 0.414258 16.1403 1.0418 16.7761C1.8416 17.6128 2.8916 17.5841 3.35918 17.6743C5.04082 17.8343 10.5 17.8835 10.5 17.8835C10.5 17.8835 14.9133 17.8753 17.85 17.6661C18.2602 17.6169 19.1543 17.6128 19.9541 16.7761C20.5857 16.1403 20.7908 14.6925 20.7908 14.6925C20.7908 14.6925 21 12.9944 21 11.2923V9.70088C21 8.00283 20.7908 6.30068 20.7908 6.30068ZM8.33027 13.2241V7.32197L14.0027 10.2833L8.33027 13.2241Z"
          fill="black"
        />
      </svg>
    </>
  );
};

export default YouTube;
