// const sleep = (milliseconds) => {
//   return new Promise((resolve) => setTimeout(resolve, milliseconds));
// };

export const restructorArray = (array, length) => {
  let size = 0,
    subArray = [];
  const parentArray = [];
  array?.forEach((each, index) => {
    subArray.push(each);
    size++;
    if (size === length) {
      parentArray.push(subArray);
      size = 0;
      subArray = [];
    }
  });
  subArray.length && parentArray.push(subArray);
  return parentArray;
};
function ordinal(number) {
  const english_ordinal_rules = new Intl.PluralRules("en", {
    type: "ordinal",
  });
  const suffixes = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
  };

  const suffix = suffixes[english_ordinal_rules.select(number)];
  return number + suffix;
}
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const dateFormatHandler = (date, hideYear, showFullMonth) => {
  let _date = date ? new Date(date).toDateString().split(" ") : new Date();
  if (hideYear) {
    if (showFullMonth) {
      return (
        monthNames[new Date(date).getMonth()] +
        " " +
        ordinal(_date[2][0] === "0" ? _date[2][1] : _date[2])
      );
    }
    return (
      _date[1] + " " + ordinal(_date[2][0] === "0" ? _date[2][1] : _date[2])
    );
  }
  return (
    _date[1] +
    " " +
    ordinal(_date[2][0] === "0" ? _date[2][1] : _date[2]) +
    " " +
    _date[3]
  );
};

export const usDateFormat = (date, divider) => {
  if (!date) {
    return "";
  }
  let _divider = divider === "line" ? "-" : "/";
  let _date = date?.split("-");
  return _date[1] + _divider + _date[2] + _divider + _date[0];
};
export const paramsReturnHandler = query => {
  if (query) {
    let search = query.substring(1)
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    )
  } else {
    return {}
  }
}
