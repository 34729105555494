import React from "react";
import decoratorMobileThree from "../../../../assets/images/decoratorSeven.png";

const DecoratorMobileThree = () =>{
    return(
        <div className="block lg:hidden ">
        <img width="100%" src={decoratorMobileThree} alt="decorator" loading="lazy"/>
        </div>
    )
}
export default DecoratorMobileThree;