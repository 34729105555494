import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { Link } from "gatsby";
import { usDateFormat } from "../../../utils";

const BlogContent = ({ border, blog, serializeContentEditor, classes }) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="w-full lg:w-3/5 mx-auto px-5 lg:pl-9">
          <Link to={`/blog/${blog?.slug?.current}`}>
            <h1 className="text-4xl leading-42 font-bold pb-3 pr-0 lg:pr-20">{blog?.title}</h1>
          </Link>
          <p className="text-dark-beige text-lg">by {blog?.author} on {usDateFormat(blog?.date, "slash")}</p>
          <BlockContent blocks={blog?.excerpt?.content} serializers={serializeContentEditor("text-lg leading-24 pr-0 lg:pr-64 py-8", true)} />
          <Link cover direction="up" to={`/blog/${blog?.slug?.current}`}>
            <button className={`cursor-pointer text-primary-red hover:text-black ${border ? "underline" : ""}`}>Read more</button>
          </Link>
        </div>
      </div>
    </>
  );
};
export default BlogContent;
