import React, { useState } from "react";
import RedButton from "../pageComponents/redButton";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
const SignUpSecond = ({ showPrivacyPolicy, event, name, leftOnMobile }) => {
  let formId = uuidv4();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    if (typeof window !== "undefined") {
      let myForm = document.getElementById(formId);
      let formData = new FormData(myForm);
      let body = new URLSearchParams(formData).toString() + `&form-name=form-${name}`;
      axios
        .post("/", body, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          setSubmitted(true);
          if (typeof window !== "undefined") {
            if (event) {
              window.dataLayer = window.dataLayer || [];
              // dataLayer.push({ event: event, eventCategory: "Form Submission", eventLabel: event });
              // eslint-disable-next-line
              dataLayer.push({ event: "autoEvent", eventCategory: "Form Submission", eventAction: "Sign up Form", eventLabel: event });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {submitted ? (
        <p className="text-red-500 text-lg pt-6">Thank You!</p>
      ) : (
        <form action="/" id={formId} onSubmit={(e) => submitHandler(e)} name={`form-${name}`} data-netlify="true">
          <div className="flex flex-col lg:flex-row justify-between pt-6 pb-4 px-0 lg:px-0">
            <div className="relative pb-8 w-full lg:w-3/4">
              <input className=" bg-transparent outline-none border-dark-beige border-b border-opacity-75 w-full pb-5 pr-12 placeholder-dark-beige" placeholder="Email Address*" type="email" required={true} name={`${name}-email`} />
              <p className="text-dark-beige pt-5 hidden lg:block">* we will never sell your information</p>
              <div className="absolute top-3 right-0 text-dark-beige">
                <svg className="w-12 " fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 8">
                  <path d="M53.353 4.354a.5.5 0 000-.708L50.172.464a.5.5 0 10-.707.708L52.293 4l-2.828 2.828a.5.5 0 10.707.708l3.181-3.182zM0 4.5h53v-1H0v1z"></path>
                </svg>
              </div>
            </div>
            <div className={`${leftOnMobile ? "text-left lg:text-center" : "text-center"}  pb-6 lg:pb-0`}>
              <RedButton disabled={loading}>Sign Up</RedButton>
            </div>
            <p className={`text-dark-beige pt-5 block lg:hidden lg:text-left pb-6 ${leftOnMobile ? "text-left" : " text-center"}`}>* we will never sell your information</p>
          </div>
          {showPrivacyPolicy && (
            <div>
              <div>
                <label className="newsletterCheckbox text-lg font-Founders font-medium">
                  Keep me up-to-date
                  <input name="up-to-date" type="checkbox" defaultChecked="checked" />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="pr-0 lg:pr-20">
                <span className="text-sm pt-2 font-Founders font-medium">
                  We will never sell your information, but by sharing your email, you agree to our{" "}
                  <a rel="noopener noreferrer" target="_blank" href="https://www.iubenda.com/terms-and-conditions/44973847" className="underline font-Founders font-medium">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a rel="noopener noreferrer" target="_blank" href="https://www.iubenda.com/privacy-policy/44973847" className="underline font-Founders font-medium">
                    Privacy Policy
                  </a>
                  .
                </span>
              </div>
            </div>
          )}
        </form>
      )}
    </>
  );
};
export default SignUpSecond;
