import React from "react";
import FacebookIcon from "../../icons/facebook";
import InstagramIcon from "../../icons/instagram";
import LinkedInIcon from "../../icons/linkedIn";
import TwitterIcon from "../../icons/twitter";
import YouTube from "../../icons/youTube";
import { Link } from "gatsby";
const MobileMenu = () => {
  return (
    <>
      <div className="mobile-menu">
        <div className="mobile-nav z-30 flex flex-col justify-around w-full fixed px-6 bg-primary-gray h-screen pt-4 overflow-y-scroll sm:pt-28 sm:pb-4 lg:pt-4 lg:pb-0">
          <div>
            <nav>
              <ul>
                <li className="text-4xl font-semibold mb-7 text-black">
                  {" "}
                  <Link to="/consulting">Our Services</Link>
                </li>
                <li className="text-4xl font-semibold mb-7 text-black">
                  {" "}
                  <Link to="/assessment-tool">Attributes Assessments</Link>
                </li>
                <li className="text-4xl font-semibold mb-7 text-black">
                  {" "}
                  <Link to="/buy">Buy The Book </Link>
                </li>
                <li className="text-4xl font-semibold mb-7 text-black">
                  <Link to="/author">About Rich</Link>
                </li>

                <li className="text-4xl font-semibold mb-7 text-black">
                  <Link to="/consulting-contact">Get in Touch</Link>
                </li>
              </ul>
              <a
                href="https://dashboard.theattributes.com/trust-fall"
                target="_blank"
              >
                <button className="mt-16 text-white text-3xl font-bold bg-black hover:bg-primary-red transition duration-300 ease-in-out  px-7 py-3 rounded-full cursor-pointer">
                  Take The Trust Fall
                </button>
              </a>
            </nav>
          </div>

          <div className="mobile-nav-social-icon mt-12 mb-20 flex">
            <div className="mr-5">
              <a
                aria-label="Facebook link"
                target="_blank"
                href="https://www.facebook.com/The-Attributes-110380034019335/?view_public_for=110380034019335"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
            </div>
            <div className="mr-5">
              <a
                aria-label="Instagram link"
                target="_blank"
                href="https://www.instagram.com/theattributes/"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
            </div>
            <div className="mr-5">
              <a
                aria-label="LinkedIn link"
                target="_blank"
                href="https://www.linkedin.com/company/theattributes"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
            </div>
            <div className="mr-5">
              <a
                aria-label="Twitter link"
                target="_blank"
                href="https://twitter.com/richdiviney"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
            </div>
            <div>
              <a
                aria-label="YouTube link"
                target="_blank"
                href="https://www.youtube.com/theattributes"
                rel="noopener noreferrer"
              >
                <YouTube />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
