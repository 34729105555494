import React from "react";
import { Link } from "gatsby";
const TopBanner = ({ top_banner }) => {
  return (
    <>
      <div className="top-banner bg-primary-red text-white font-brother relative z-20">
        <div className="mx-auto container flex flex-col lg:flex-row justify-between items-center p-4 text-15 leading-5 px-3 lg:px-17">
          <Link to={top_banner?.leftContentLink}>
          <span href="/" className="font-extrabold hidden lg:block uppercase">
            {top_banner?.left_content}
          </span></Link>
          <p className="mx-0 mb-5 lg:mb-0 text-center">
            {top_banner?.center_content}
          </p>
          <Link to={top_banner?.link} className="font-extrabold uppercase ">
            {top_banner?.right_content}
          </Link>
        </div>
      </div>
    </>
  );
};

export default TopBanner;
