import React from "react";
import SignupRightButton from "../signUpWithRightButton";
const UpToDateForm = ({ noRounded, section, event }) => {
  return (
    <>
      <div className="lg:max-w-5xl mx-auto px-0 md:px-6 lg:px-16 xl:px-0">
        <div className={`bg-extra-dark-gray px-6 lg:px-0 ${noRounded ? "rounded-none lg:rounded-lg" : "rounded-lg"}`}>
          <h1 className="text-5xl leading-54 font-bold text-left lg:text-center py-8">{section?.title}</h1>
          <p className="leading-32 text-2xl text-left lg:text-center lg:px-20">{section?.description}</p>
          <div className="lg:w-3/4 mx-auto lg:px-8">
            <SignupRightButton event={event} name="newsletter" />
          </div>
        </div>
      </div>
    </>
  );
};
export default UpToDateForm;
