import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
const DownloadForm = ({ text, name, event }) => {
 
  let formId = uuidv4();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    if (typeof window !== "undefined") {
      let myForm = document.getElementById(formId);
      let formData = new FormData(myForm);
      let body = new URLSearchParams(formData).toString() + `&form-name=form-${name}`;
      axios
        .post("/", body, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          setSubmitted(true);
          if (typeof window !== "undefined") {
            if (event) {
              window.dataLayer = window.dataLayer || [];
              // eslint-disable-next-line
              dataLayer.push({ event: "autoEvent", eventCategory: "Form Submission", eventAction: "Sign up Form", eventLabel: event });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {submitted ? (
        <p className="text-red-500 text-lg">Thank You!</p>
      ) : (
        <form action="/" id={formId} onSubmit={(e) => submitHandler(e)} className="flex flex-col" name={`form-${name}`} data-netlify="true">
          <div className="relative pb-4">
            <input className=" bg-transparent outline-none border-dark-beige border-b border-opacity-75 w-full pb-3 pr-12 placeholder-dark-beige" placeholder="Email Address*" type="email" required={true} name={`${name}-email`} />
            <div className="absolute top-3 right-0 text-dark-beige">
              <svg className="w-12 " fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 8">
                <path d="M53.353 4.354a.5.5 0 000-.708L50.172.464a.5.5 0 10-.707.708L52.293 4l-2.828 2.828a.5.5 0 10.707.708l3.181-3.182zM0 4.5h53v-1H0v1z"></path>
              </svg>
            </div>
          </div>
          <div>
            <button disabled={loading} className="text-primary-red hover:text-black g-recaptcha" data-sitekey="6LeZLKgZAAAAALNR8unLBpLrO0OdgzItIcIS3arC">
              {text}
            </button>
          </div>
        </form>
      )}
    </>
  );
};
export default DownloadForm;
