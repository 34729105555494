import React from "react";
const Tiktok = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
      >
        <path
          d="M11.1014 9.43965e-05V16.4069C11.0946 18.3196 9.53573 19.8685 7.61333 19.8685C6.48049 19.8685 5.47398 19.3307 4.83659 18.4978C4.69436 18.4263 4.55792 18.3454 4.42769 18.2559C4.55796 18.3454 4.69465 18.4263 4.83693 18.4978C4.37428 17.8937 4.1243 17.1549 4.12542 16.3952C4.12542 14.4772 5.68697 12.922 7.6135 12.922C7.97254 12.9219 8.32935 12.9768 8.67152 13.0849V8.88529C8.27165 8.83072 7.8682 8.80758 7.46481 8.81608V12.0848C7.46476 12.0848 7.46472 12.0848 7.46463 12.0848V8.81608C3.32745 8.90118 0 12.2673 0 16.4071C0 18.4736 0.828655 20.3469 2.17339 21.7157C2.17342 21.7157 2.17336 21.7159 2.17339 21.7159C2.17356 21.716 2.17362 21.7161 2.17408 21.7161C3.5587 23.1256 5.48938 24 7.62545 24C11.8369 24 15.2512 20.6006 15.2512 16.407V8.0245C16.8715 9.17773 18.8563 9.85627 21 9.85627V5.74849C20.5943 5.74858 20.1896 5.70656 19.7926 5.62281V5.62264C18.596 5.36885 17.5368 4.74624 16.7404 3.8802C15.9123 2.97939 15.3687 1.81533 15.2511 0.528348V0H14.1069L11.1014 9.43965e-05ZM14.3399 0.892938C14.4071 1.08486 14.4841 1.2721 14.5705 1.45415C14.4841 1.27211 14.407 1.08486 14.3399 0.892938ZM14.8571 1.98402C14.9397 2.11914 15.0291 2.24949 15.1222 2.37699C15.0291 2.24946 14.9397 2.11917 14.8571 1.98402ZM14.0435 7.02436C14.1448 7.09644 14.2474 7.16668 14.3515 7.235C14.2474 7.16668 14.1448 7.0966 14.0435 7.02453V7.02436ZM14.6679 7.43425C14.9882 7.62763 15.3205 7.8034 15.6633 7.96027C16.1204 8.16943 16.596 8.34492 17.0874 8.4836C17.7015 8.65696 18.34 8.77277 18.9965 8.82479C19.2591 8.84562 19.5245 8.85612 19.7924 8.85612V8.85629C19.5245 8.85629 19.2591 8.84559 18.9965 8.8248C18.34 8.77279 17.7015 8.65697 17.0874 8.48362C16.596 8.34494 16.1204 8.16944 15.6633 7.96028C15.3205 7.80341 14.9882 7.62764 14.6679 7.43426V7.43425ZM3.19205 14.0434C3.01561 14.4589 2.91821 14.9158 2.91821 15.3953C2.91821 16.4124 3.35705 17.3272 4.05663 17.9624C3.35694 17.3272 2.91804 16.4124 2.91804 15.3953C2.91804 14.9158 3.01556 14.4589 3.19205 14.0434Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default Tiktok;
