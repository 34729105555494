import React from "react";
import { getImageSize } from "../../../../sanity";
const ThreeCircles = () => {
  const { width, height } = getImageSize(
    "d4d06a6ac2632b6f4bdf6ec36e6eb94bdf1273db-554x478.webp"
  );
  return (
    <>
      <img
        height={height}
        width={width}
        src="https://cdn.sanity.io/images/imclblh9/production/d4d06a6ac2632b6f4bdf6ec36e6eb94bdf1273db-554x478.webp"
        alt="circles"
        loading="lazy"
      />
    </>
  );
};

export default ThreeCircles;
