import React from "react";
import DecoratorExtraCircles from "../../../../assets/images/decoratorThree.png";
const DecoratorMoreCircles = () => {
  return (
    <div className="hidden lg:block">
      <img width="100%" src={DecoratorExtraCircles} alt="decorator" loading="lazy" />
    </div>
  );
};
export default DecoratorMoreCircles;
