import React from "react";
const RedButton = (props) => {
  return (
    <>
      <button disabled={props?.disabled} className="text-white hover:bg-black bg-primary-red transition duration-300 ease-in-out px-12 py-3  lg:px-2 xl:px-7 lg:py-3 rounded-full cursor-pointer">
        {props.children}
      </button>
    </>
  );
};
export default RedButton;
