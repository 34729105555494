import React, { useEffect } from "react";
import { useState } from "react";
import Header from "./header";
import Footer from "./footer";
import MobileMenu from "./mobileMenu";
import TopBanner from "./topBanner";
import "../../assets/styles/style.css";
import { Helmet } from "react-helmet";
import { generateImageUrl } from "../../sanity";

const Layout = ({
  children,
  darkTheme,
  className,
  pageTitle,
  social,
  location,
  pageSlug,
  top_banner,
  settings,
  header_options,
}) => {
  const [mobileNav, setMobilenav] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      let body = document.getElementsByTagName("body")[0];
      if (mobileNav) {
        window?.scrollTo(0, 0);
        body.style.height = "100vh";
        body.style.overflowY = "hidden";
      } else {
        body.style.height = "100%";
        body.style.overflowY = "scroll";
      }
    }
  }, [mobileNav]);

  return (
    <div className={className}>
      <Helmet>
        <html lang="en" />
        <title>{pageTitle}</title>
        <meta property="og:site_name" content="The Attributes" />
        <meta property="og:title" content={social?.title} />
        <meta property="og:type" content="article" />
        <meta name="twitter:title" content={social?.title} />
        <meta property="og:description" content={social?.desc} />
        <meta name="description" content={social?.desc} />
        <meta name="twitter:description" content={social?.desc} />
        <meta
          property="og:image"
          content={
            social?.image?.asset
              ? generateImageUrl(social?.image?.asset).width(1200).url()
              : ""
          }
        />
        <meta
          name="twitter:image"
          content={
            social?.image?.asset
              ? generateImageUrl(social?.image?.asset).width(1200).url()
              : ""
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta property="og:url" content={location?.origin + "/" + pageSlug} />
        <meta name="twittter:url" content={location?.origin + "/" + pageSlug} />
        <meta property="og:article:author" content="Rich Diviney" />
        <script
          async
          defer
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          type="text/javascript"
        />
        <script
          async
          defer
          src="https://apps.elfsight.com/p/platform.js"
          type="text/javascript"
        />
      </Helmet>
      {typeof window !== "undefined" && (
        <div id="router-view" className={`router-view`}>
          {!mobileNav && top_banner?.show && (
            <TopBanner top_banner={top_banner} />
          )}
          <Header
            setMobilenav={setMobilenav}
            mobileNav={mobileNav}
            darkTheme={darkTheme}
            header_options={header_options}
          />
          {mobileNav && <MobileMenu />}
          {children}
          <Footer settings={settings} />
        </div>
      )}
    </div>
  );
};
export default Layout;
